<script setup lang="ts">
import {
  addDays,
  endOfDay,
  format,
  isEqual,
  isToday,
  min,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  subDays,
} from "date-fns";
import { fr } from "date-fns/locale";
import type { HTMLAttributes } from "vue";
import type { Period, Range } from "~/server/constants/sale";
import { PERIODS } from "~/server/constants/sale";
import { getSelectedPeriod } from "~/utils/sale";

type Props = {
  selectedRange: Range;
  class?: HTMLAttributes["class"];
};

const props = defineProps<Props>();

const selectedRange = ref<Range>(props.selectedRange);

const emit = defineEmits<{
  "update:selectedRange": [value: Range];
}>();

const route = useRoute();

const open = ref(false);

watch(selectedRange, async () => {
  open.value = false;

  const start = startOfDay(selectedRange.value.start);
  const end = isToday(selectedRange.value.end)
    ? new Date()
    : endOfDay(selectedRange.value.end);

  emit("update:selectedRange", { start, end });

  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      periodStart: start.toISOString(),
      periodEnd: end.toISOString(),
    },
  });
});

const { t, locale } = useI18n({
  useScope: "local",
});
const dateFormat = (date: Date) =>
  format(date, "dd LLLL", {
    locale: locale.value === "fr" ? fr : undefined,
  });

const displayedPeriod = computed(() => {
  const selectedPeriod = getSelectedPeriod(selectedRange.value);

  return selectedPeriod
    ? t(selectedPeriod)
    : isEqual(
          startOfDay(selectedRange.value.start),
          startOfDay(selectedRange.value.end),
        )
      ? `${dateFormat(selectedRange.value.start)}`
      : `${dateFormat(selectedRange.value.start)} - ${dateFormat(selectedRange.value.end)}`;
});

function getSelectedPeriodStart(period: Period) {
  switch (period) {
    case "today":
      return startOfDay(new Date());
    case "this-week":
      return startOfISOWeek(new Date());
    case "this-month":
      return startOfMonth(new Date());
    default:
      throw new Error(`Invalid period: ${period}`);
  }
}

function setPeriod(period: Period) {
  open.value = false;
  selectedRange.value = {
    start: getSelectedPeriodStart(period),
    end: new Date(),
  };
}

const isPeriodSelected = (period: Period) => {
  return (
    isEqual(
      startOfDay(selectedRange.value.start),
      getSelectedPeriodStart(period),
    ) && isEqual(endOfDay(selectedRange.value.end), endOfDay(new Date()))
  );
};

const startDaySelected = ref<Date>();

const minDate = computed(() => {
  if (!startDaySelected.value) return;

  return startOfDay(subDays(startDaySelected.value, 30));
});

const maxDate = computed(() => {
  const now = new Date();
  if (!startDaySelected.value) return now;

  const possibleMaxDate = addDays(startDaySelected.value, 30);

  return min([isToday(possibleMaxDate) ? now : endOfDay(possibleMaxDate), now]);
});

watch(selectedRange, () => {
  startDaySelected.value = undefined;
});
</script>

<template>
  <UIPopover v-model:open="open">
    <UIPopoverTrigger as-child>
      <button
        id="date"
        :class="
          cn(
            'flex items-center justify-start rounded-lg px-4 py-3 text-left font-normal outline outline-1 outline-gray-400 md:w-[250px]',
            props.class,
          )
        "
      >
        <Icon
          name="mdi:calendar-blank-outline"
          class="mr-4 size-4 text-on-surface"
        />

        <span class="sm:text-sm">
          {{ displayedPeriod }}
        </span>
      </button>
    </UIPopoverTrigger>
    <UIPopoverContent class="w-auto p-0" align="end">
      <div
        class="flex w-[calc(100vw-32px)] flex-col divide-y divide-outline/40 rounded-md outline outline-1 outline-outline/20 sm:w-full sm:flex-row sm:divide-x sm:divide-y-0"
      >
        <div class="flex flex-col py-4">
          <button
            v-for="(period, index) in PERIODS"
            :key="index"
            class="w-full rounded-none px-6 py-2 text-left sm:text-sm"
            :class="[
              isPeriodSelected(period)
                ? 'bg-surface-variant'
                : 'hover:bg-surface-variant/90 focus-visible:bg-surface-variant/90',
            ]"
            @click="setPeriod(period)"
          >
            {{ t(period) }}
          </button>
        </div>

        <UILegacyCalendar
          v-model.range="selectedRange"
          :columns="1"
          :min-date="minDate"
          :max-date="maxDate"
          @day-click="(value) => (startDaySelected = value)"
          class="z-50"
        />
      </div>
    </UIPopoverContent>
  </UIPopover>
</template>

<i18n lang="json">
{
  "en": {
    "today": "Today",
    "this-week": "This week",
    "this-month": "This month"
  },
  "fr": {
    "today": "Aujourd'hui",
    "this-week": "Cette semaine",
    "this-month": "Ce mois"
  }
}
</i18n>
